import React from 'react'
import { CgSpinner } from "react-icons/cg";

export default function index() {
  return (
   
        <CgSpinner
        className="animate-spin h-full w-full"
        ></CgSpinner>
   
  )
}

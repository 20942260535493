import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import Link from "next/link";

import Spinner from "../components/Spinner";
import HeaderLogo from "../components/HeaderLogo";
import { SvgAsanaLogo, SvgLogo } from "../components/Svg";

const Login: NextPage = () => {
  const router = useRouter();

  const { user, isLoading, isError } = useAuth();

  const [loading, setLoading] = useState(false);

  const asanaButton = () => {
    // Store this in env later when it works!
    setLoading(true);
    const path = "https://app.asana.com/-/oauth_authorize";
    const clientId = process.env.NEXT_PUBLIC_ASANA_CLIENT_ID;
    let redirectURI = ((process.env.NEXT_PUBLIC_BASE_URL as string) +
      process.env.NEXT_PUBLIC_ASANA_REDIRECT_PATH) as string;
    router.push(
      `${path}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
        redirectURI
      )}&response_type=identity`
    );
  };

  useEffect(() => {
    if (!user) return;

    console.log("USER:", user)
    // router.push("/dashboard")
  }, [user, router]);

  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_APP_TITLE} Login</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex min-h-screen flex-1 flex-col justify-center items-center bg-indigo-50 relative">
        <div className="flex h-16 shrink-0 items-center mt-6 ml-6 absolute top-0 left-0 ">
          <SvgLogo></SvgLogo>
        </div>
        <div className="w-10/12 md:w-[480px] flex flex-col items-center">
          <div className=" w-full flex items-start  lg:justify-start">
            <div className=" flex flex-col gap-2">
              <h2 className="text-2xl font-bold text-[#020217]">
                Get started absolutely free
              </h2>
              <p className="text-[#9191AB]">
              Pay as you grow.
              </p>
            </div>
          </div>

          <div className="w-full mt-10">
            <div className="bg-transparent ">
              <div className="flex flex-col items-start">
                <button
                  onClick={asanaButton}
                  type="submit"
                  className="flex w-full mx-auto h-12 rounded-md bg-[#020217] px-3 py-1.5 text-[18px] font-[600] leading-6 text-white shadow-sm hover:bg-[#020217]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                   <div className="flex relative w-full h-full items-center justify-start">
                    {loading ? <div className="w-1/4 h-6"><Spinner></Spinner></div> : <div className="w-1/4 h-6"><SvgAsanaLogo></SvgAsanaLogo></div>}
                    <p className="w-full text-left">Connect using Asana</p>
                  </div>
                </button>
              </div>
              
              <p className="text-center md:text-left w-full px-4 md:px-0 md:w-11/12 mb-6 mt-6 text-[14px] leading-6 tracking-tight text-[#9191AB]">
                By connecting, I agree to Cloudmate{" "}
                <a href="https://www.cloudmate.works/terms-of-service">
                  <u className="font-bold hover:cursor-pointer text-[#020217]">
                    Terms of Service
                  </u>
                </a>
                {" "} and{" "}
                <a href="https://www.cloudmate.works/privacy-policy">
                  <u className="font-bold hover:cursor-pointer text-[#020217]">
                    Privacy Policy.
                  </u>
                </a>
              </p>

              <div>
                <div className="relative mt-10">
                  <div
                    className="absolute inset-0 flex items-center" aria-hidden="true">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
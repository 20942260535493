import useSWR from "swr";

const fetcher = async (url: string) => {
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

const useAuth = () => {
  const { data, error } = useSWR("/api/user", fetcher);

  return {
    user: data ?? null,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useAuth;
